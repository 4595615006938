import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class OrcsVsElvesInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            Casual game about the war between Orcs and Elves.
            <br />
            <br />
            Game by eddynardo.
            <br />
            Graphics by 0×72.
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Instructions:</u>
            <br />
            Use your mouse to defeat the armies of Orcs and Elves. Don’t let
            them reach each other because they might find peace and nobody wants
            that.
            <br />
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
